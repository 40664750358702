(() => {
	const cookie_name = 'cookie-notification';
	const cookie_el = document.querySelector('.cookie-notification');

	// If the cookie element is not available, return out now
	if (!cookie_el) return;

	// Get cookie
	let cookieNotify = Cookies.get(cookie_name);

	// If cookie is not set, show the cookie message
	if (!cookieNotify) {
		cookie_el.classList.add('show');

		// Get the close button
		const cookie_close_el = document.querySelector('.cookie-notification-close');

		// Add close button eveent
		cookie_close_el.addEventListener('click', (e) => {
			e.preventDefault();
			cookie_el.classList.remove('show');
			Cookies.set(cookie_name, true, {
				expires: 30
			})
		});
	}
})();